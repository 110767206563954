/* styles.scss
   1.NOTIFICATIONS
*/
@import '~@fortawesome/fontawesome-svg-core/styles.css';
h, p, div, a{
    /*font-family:raleway,sans-serif;*/
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size:14px;
}
body, header, h, a, p, div, span, ul, li{
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
    margin:0 0 0 0;
    padding:0 0 0 0;
    color:#212529;
}
a{
    color:#007bff;
}
.page_container{
    float:none;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
}
/*.options_arrow: before{
    content: "\e006";
}*/
.options_arrow{
    color:#333333;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.element_loading_container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Black with 25% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999; /* Ensure it's on top */
}
.element_loading_container .icon_cont,.element_loading_container .icon_cont .fa-arrows-rotate{
    color:#0670cd;
    width:26px;
    height:26px;
}
.element_loading_container .icon_cont .fa-arrows-rotate{
    animation: rotate 0.9s linear infinite;
}
/*
    1.LOGIN PAGE
    2.MAIN PAGE
    3.MODAL CSS
    4.GENERAL
*/

/*  1.LOGIN PAGE  */
#login_page .header{
    float:left;
    width:calc(100% - 20px);
    margin:0 10px;
    border-bottom:#000 1px solid;
    padding-bottom:1.8em;
}
#login_page .header .logo{
    float:left;
    width:100%;
    max-width:300px;
    margin-top:2em;
    max-width:300px;
}
#login_page .header p {
    float:left;
    font-size:1.2em;
    margin:3em 0 0 1em;
}
#login_page h1{
    float:left;
    width:100%;
    clear:both;
    font-size:3em;
    line-height:1.5em;
    color:rgb(0,47,119);
    margin-top:2em;
    text-transform: uppercase;
}

#login_page #login_cont{
    float:none;
    clear:both;
    margin-left:auto;
    margin-right:auto;
    max-width:930px;
    margin-top:30px;
}

#login_page #login_cont .text{
    text-align: center;
    font-size: 15px;
    line-height:32px;
}

#login_page #login_cont form{
    float:none;
    max-width:410px;
    margin:100px auto 0 auto;
}
#login_cont form .input_label{
    float:left;
    color:#333;
    font-size:1.4em;
    line-height:24px;
    padding:10px 0
}
#login_cont form input{
    clear:both;
    width:100%;
    max-width:calc(410px - 10px);
    height:30px;
    padding:5px;
    border:#adadad 1px solid;
    background-color:#fff;
}
#login_cont form input[type=submit]{
    float:right;
    width:auto;
    height:40px;
    padding:0px 30px;
    margin: 10px 0 0 0;
    color:#333;
    font-size:1.2em;
    line-height:36px;
    background:none;
}
#login_cont #login_form .link{
    float:right;
    clear:both;
    margin-top:1em;
}






/* 2.MAIN PAGE  */

#main_page #map{
    position:fixed;
    width:100%;
    height:100%;
    z-index:1;
}
#main_page  #interactive_map_container{

}
#main_tool_bar_cont #icon_panel{
    position:fixed;
    top:0;
    left:0;
    z-index:2;
    width:51px;
    height:100%;
    border-right:#f2f2f2 1px solid;
    background-color:#fff;
}
#main_tool_bar_cont #icon_panel ul{
    list-style-type: none;
}
#main_tool_bar_cont #icon_panel li{
    float:left;
    width:100%;
    clear:both;
    line-height:20px;
    padding:7px 0;
    text-align:center;
    font-size:18px;
    color:#ababab;
}
#main_tool_bar_cont #icon_panel .top_border{
    border-top:#ababab 1px solid;
}



/*   3.MODAL CSS    */
.modal-overlay {
    float:left;
    width:auto;
    height:auto;
    position:relative;
    z-index:10000;
}
.modal-overlay .modal-body {
    background-color:#fff;
}
.modal-overlay .modal-header{
    background-color:#f5f5f5;
}


/* 4.GENERAL */
.arrow-up {
  width: 0;
  height: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-bottom: 1em solid black;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: 1em solid black;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 1em solid transparent;
  border-bottom: 1em solid transparent;
  border-left: 1em solid black;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 1em solid transparent;
  border-bottom: 1em solid transparent;
  border-right:1em solid black;
}

.align-right{
    text-align:right;
}
.align-center{
    text-align:center;
}
/* 1.NOTIFICATIONS */
.smallNotification{
    max-width:340px;
}
.smallNotification .popup-body{
    height:auto;
    min-height:80px;
    padding-bottom:10px;
}

#SSASCornerPopup{
    position:fixed;
    bottom:0;
    right:0;
    width:100%;
    max-width:180px;
}
#SSASCornerPopup .popup-footer{
    display:none;
}
 
/*#SSASInfoCorner .liveAlert, #SSASInfoCorner .testAlert, #SSASInfoCorner .testMode{*/
#SSASCornerPopup .liveAlert, #SSASCornerPopup .testAlert, #SSASCornerPopup .testMode{
    float:left;
    width:100%;
}
#SSASCornerPopup .liveAlert p, #SSASCornerPopup .testAlert p, #SSASCornerPopup .testMode p{
    float:left;
    width:auto;
}
#SSASCornerPopup .liveAlert button, #SSASCornerPopup .testAlert button, #SSASCornerPopup .testMode button{
    float:left;
    width:auto;
}
/*.smallNotification .popup-header,
.smallNotification .popup-footer,*/


/*
.loading_gif{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background:#fff 0.5;
    z-index:15000;
}
.loading_gif p{
    position:absolute;
    left:calc(50% - 50px);
    top:calc(50% - 25px);
    width:100px;
    height:50px;
    line-height:50px;
}
.loading_gif::before {
    content: "\f021";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    font-size: 1.5em;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}*/

/*.loading_gif p{
    position:absolute;
    left:calc(50% - 100px);
    top:calc(50% - 25px);
    width:200px;
    height:50px;
    line-height:50px;
    display: inline-block;
    color: #0071d9;
    animation: rotate 1s linear infinite;
}*/