.details_page #details_SSAS_tab{
    padding-top:10px;
}
.details_page #details_SSAS_tab .row{
    margin-left:0;
}
#ssas_page{
    width:100%;
    max-width:100%;
}
#ssas_page .header{
    float:left;
    width:100%;
    clear:both;
}
#ssas_page .header h1{
    float:left;
    font-weight:400;
    font-size:54px;
}
#ssas_page #ssas_tab_cont{
    float:left;
    clear:both;
    width:100%;
}
#ssas_page .tab-content {
    float:left;
    overflow-x:auto;
    padding-bottom:40px;
}
#ssas_page .tab-content th,
#ssas_page .tab-content td{
    padding:0 5px 0 5px;
}
#ssas_page .tab-content #ssas_tab_cont-dashboard .col{
    float:left;
    max-width: 50%;
}
#ssas_page .tab-content .container {
    max-width:100%;
}
#ssas_page .tab-content .container h2{
    float:left;
    font-weight:400;
    margin-top:40px;
}
#ssas_page .tab-content .badge{
    width:100%;
    font-size:16px;
    line-height:26px;
    border-radius:5px;
    padding:0 0 0 0;
}

#details_SSAS_tab .custom-table-row .badge{
    width:calc(100% - 20px);;
    font-size:16px;
    line-height:26px;
    border-radius:5px;
    padding:0 0 0 0;
    text-align: center;
    margin-left: 10px;
}

#ssas_page .tab-content .badge.bg-warning, #ssas_page .tab-content .badge.bg-light{
    color:#000;
}

#ssas_page #ssasAlertHeader{
    padding-bottom:55px;
}
#ssas_page .contacts_cont .btn_cont{
    margin-top:-50px;
    position:relative;
    z-index:100;
}

#ssas_page .contacts_cont .btn_cont .btn{
    float:right;
    margin-left:20px;
}
#ssas_page .ssas_contact_expand_cont .export_cont{
    display:none;
}
#ssas_page .custom-table .log_date{
    width:10%;
    min-width:10%;
    max-width:10%;
}
#ssas_page .custom-table .log_type{
    width:11%;
    min-width:11%;
    max-width:11%;
}
#ssas_page .custom-table .log_mode{
    width:12%;
    min-width:12%;
    max-width:12%;
}
#ssas_page .custom-table .contact_details_user_email{
    width:14%;
    min-width:14%;
    max-width:14%;
}
#ssas_page .custom-table .contact_details_user_name{
    width:14%;
    min-width:14%;
    max-width:14%;
}
#ssas_page .custom-table .contact_details_user_company{
    width:10%;
    min-width:10%;
    max-width:10%;
}

#ssas_page .custom-table td.log_details{
  display: -webkit-box;            /* Use flexbox model for layout */
  -webkit-line-clamp: 2;           /* Limit the number of lines to 2 */
  -webkit-box-orient: vertical;    /* Set box orientation to vertical */
  overflow: hidden;                /* Hide overflowed content */
  text-overflow: ellipsis;         /* Apply ellipsis */
  line-height: 1.5;                /* Adjust line height as necessary */
  max-height: 3em;
  width:100%;
  max-width:100%;
}
#ssas_page .custom-table .log_details{
    width:14%;
    min-width:14%;
    max-width:14%;
}
#ssas_page .custom-table .ssas_vessel_details_vessel_name{
    width:14%;
    min-width:14%;
    max-width:14%;
}
#ssas_page .custom-table .ssas_vessel_details_locate{
    width:6%;
    min-width:6%;
    max-width:6%;
    max-width:6%;
}
.details_page #details_SSAS_tab #current_ssas_status{
    line-height: 30px;
    font-size: 16px;
    margin-left:10px;
}
#details_SSAS_tab #current_ssas_status, #details_SSAS_tab .button_padding{
    padding: 0px 10px;
    line-height:30px;
}
#details_SSAS_tab #vessel_email_for_ssas{
    font-size:12px;
    max-width:200px;
}
#details_SSAS_tab #vessel_email_for_ssas, #details_SSAS_tab .input_padding{
    padding: 0px 10px;
    line-height:28px;
}
.details_page #details_SSAS_tab .accordion{
    overflow-x:auto;
}
.details_page #details_SSAS_tab .accordion-body{
    padding-top:30px;
    padding-bottom:30px;
}
.details_page #details_SSAS_tab .schedule_test_btn{
    float:left;
}
.details_page #details_SSAS_tab .custom-table{
    float:left;
    margin-top:20px;
    width:100%;
}
.details_page #details_SSAS_tab .custom-table .table_header_text,
#ssas_page .custom-table .table_header_text{
    text-transform:uppercase;
}
.details_page #details_SSAS_tab .custom-table th,
#ssas_page .custom-table th{
    vertical-align:top;
}
.details_page #details_SSAS_tab .custom-table th,
.details_page #details_SSAS_tab .custom-table td,
#ssas_page .custom-table th,
#ssas_page .custom-table td{
    padding:0 5px;
}
.details_page #details_SSAS_tab .center_text,
#ssas_page .center_text{
    float:left;
    width:100%;
    text-align:center;
}
.details_page #details_SSAS_tab #schedule_test_btn{
    float:right;
    margin-top:-50px;
    font-weight:bold;
}
/*vessel_email_for_ssas*/
.details_page #details_SSAS_tab #vessel_email_error_notice,
.details_page #details_SSAS_tab #must_be_master_error{
    display:none;
    color:red;
    clear:both;
    float:right;
}
.test_schedule_expand{
    padding:20px 0 10px 0;
}
.test_schedule_expand .test_status_btn{
    color:#000;
    background: #07caf0;
    border:none;
}
.test_schedule_expand .test_status_btn:hover{
    /*color:#000;*/
    background: #01afd1;
}
.details_page .test_schedule_expand{
    float:left;
}
.details_page .test_schedule_expand .col{
    float:left;
    width:auto;
    max-width:none;
}
.details_page .test_schedule_expand .text{
    float:left;
    font-size:12px;
}
.details_page .test_schedule_expand .column1{
    max-width:120px;
}
.details_page .test_schedule_expand .column2{
    max-width:200px;
}
.details_page .test_schedule_expand .column2 input{
    font-size:12px;
    height:30px;
    line-height:30px;
}
.details_page .test_schedule_expand .column3{
    max-width:140px;
}
.details_page .test_schedule_expand .column3 button{
    font-size:12px;
    height:30px;
    line-height:18px;
    font-weight:bold;
}
.details_page .contact_cont{
    float:right;

}
.details_page .overlay, #ssas_page .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); /* Black with 25% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top */
}
.details_page .centered-content,
#ssas_page .centered-content {
  position: relative; /* To position it relative to the overlay */
  z-index: 1000; /* Ensure it's above the overlay */
  background-color: white; /* Example background color */
  padding: 20px; /* Example padding */
}

.details_page .centered-content,
#ssas_page  .centered-content {
  max-width: 80%; /* Example maximum width */
  text-align: center; /* Example text alignment */
}
.date_picker_container{
}
.date_picker_container .react-datepicker{
    height:260px;
}
.date_picker_calendar{
    float:left;
}
.date_picker_calendar h2.react-datepicker__current-month, .date_picker_time .react-datepicker-time__header {
    font-weight:normal;
}
.date_picker_calendar .react-datepicker__day, .date_picker_time li.react-datepicker__time-list-item{
    font-size:12px;
}
.date_picker_time{
    float:left;
}
.date_picker_time .react-datepicker-time__header{
    font-size:12px;
}
.date_picker_time li.react-datepicker__time-list-item{
    height:26px;
}
.date_picker_time .react-datepicker-time__header{
    font-weight:normal;
}
.start_time{
    float:left;
}
.end_time{
    float:left;
}
.date_picker_submit{
    float:right;
    /*clear:both;*/
}



/*{
    position: absolute;
    z-index: 101;
    background-color: #fff;
    top: calc(50% - 166px);
    left: calc(50% - 121px);
}*/