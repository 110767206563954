/*  1.DATE PICKER
    2.CUSTOM TABLE
*/

/*  1.DATE PICKER  */
.date_picker_window_header{
    float:left;
    width:100%;
    position:absolute;
    top:0px;
    left:0;
    background-color:#f5f5f5;
    height:40px;
    line-height:40px;
    padding:0 10px;
}
.date_picker_window_header p{
    float:left;
    margin-left:5px;
    font-weight:500;
}
.date_picker_calendar, .date_picker_time.start_time, .date_picker_time.end_time {
    margin-top:40px;
}
.date_picker_time.start_time .react-datepicker-time__header, .date_picker_time.end_time .react-datepicker-time__header{
    font-weight:bold;
}
.window_icon {
    float:left;
}
.window_icon svg, .window_icon svg::before, .window_close_icon svg, .window_close_icon svg::before{
    color:#333;
    width:20px;
    height:18px
}
.window_close_icon{
    float:right;
    height:30px;
    line-height:16px;
    margin-top:6px;
    margin-right:-5px;
    padding:5px;
    text-align:center;
}
.window_close_icon:hover{
    border : #ccc 1px solid;
    background-color : #e6e5e5;
    border-radius : 5px;
    margin-top:5px;
    margin-right:-6px;
}

.content_header .icon_container .date_picker_container button{
    float:left;
    border: #ccc 1px solid;
    background: none;
    padding:0 10px 0 10px;
    margin: 0 0 0 0;

}


/*  2.CUSTOM TABLE  */
.tab-content,
.tab-content .custom-table{
    float:left;
    width:100%;
    clear:both;
}
.tab-content thead th{
    position:relative;
    text-transform:uppercase;
}
.tab-content thead .table_header_text{
    float:left;
    width:100%;
    padding: 5px 0;
    line-height:18px;
    clear:both;
    text-align:left;
}
.tab-content thead .table_header_text:has(.center_header){
    text-align:center;
}
.tab-content thead .table_header_text:has(.right_header){
    text-align:right;
}
.tab-content thead .sorting_arrow{
    font-size:10px;
    position: absolute;
    top: 10px;
    bottom: 10px;
    margin-left:10px;
}
.tab-content thead input{
    width:100%;
    min-width:110px;
    max-width:180px;
}
.tab-content thead input, #ssas_page .tab-content thead select,
.accordion-body thead input, #ssas_page .accordion-body thead select{
    float:left;
    clear:both;
    color: #333;
    height:20px;
    border-color: #ccc;
    background-color: #fff;
    border: #cccccc 1px solid;
    border-radius: 4px 0 0 4px;
    margin-bottom:5px;
}
.tab-content thead input::placeholder {
    color:#fff;
}

.export_cont{
    float:left;
    clear:both;
    width:100%;
    max-width:150px;
    margin:20px 0 0 0;
}
.export_cont .export_csv_button,
.export_cont .export_excel_button{
    border: #ccc 1px solid;
}
.export_cont .export_excel_button{
    margin-left:10px;
}
.custom-table_component{
    float:left;
    width:100%;
    padding-bottom:10px;
}
.custom-table_cont .export_csv_button,
.custom-table_cont .export_excel_button {
    float:left;
    background-color:#fff;
    border: #cccccc 1px solid;
    border-radius: 4px;
    line-height: 4px;
    width: 30px;
    height: 30px;
    margin-right:10px;
}
.custom-table_cont{
    max-height: 500px;
    overflow-y: auto;
    border-bottom: #cccccc 1px solid;
}
.custom-table_cont .custom-table table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table_cont .custom-table thead th {
    position: sticky;
    top: 0;
    background-color: #fff;/*#f4f4f4;*/
    z-index: 1;
}

.custom-table_cont .custom-table th, .custom-table_cont .custom-table td {
    /*border: 1px solid #ccc;*/
    padding: 5px;
}

.tab-content .pagination{
    float:right;
    margin-top:20px;
    margin-right:calc(50% - 75px);
}

.tab-content .change_page_cont{
    float:left;
    width:100%;
    clear:both;
}
.tab-content .change_page_cont button,
.tab-content .change_page_cont p{
    float:left;
    height:26px;
    /*line-height: 26px;*/
    background-color:#fff;
    border: #cccccc 1px solid;
    border-radius: 4px;
    min-width:30px;
    text-align:center;
}
.tab-content .change_page_cont button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.tab-content .change_page_cont button span{
    display:block;
    float:none;
    font-size:5px;
    margin-top:2px;
    padding:0 1px;
    margin-left: auto;
    margin-right: auto;
}
.tab-content .change_page_cont p{
    line-height: 25px;
}
.custom-table th{
    font-size:12px;
    vertical-align:top;
}
.custom-table thead tr{
    border-bottom:#dedede 1px solid;
}
.custom-table tbody tr{
    border-top:#dedede 1px solid;
    height:40px
}
.custom-table td{
    font-size:12px;
}

/* Notification */
.popup-content {
    width:100%;
}
.popup-container {
  float:left;
  padding: 0 0 10px 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius:5px;
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-header {
  float:left;
  width:100%;
  max-width: 100%;
  height:54px;
  line-height:54px;
  margin-bottom: 10px;
  border-bottom: #dfe1e5 1px solid;
}
.popup-header .title{
    float:left;
}
.popup-header .close_icon{
    float:right;
}
.popup-header .close_icon button, .popup-header .close_icon button:hover{
    float:right;
    color:#212529;
    border:none;
    font-size: 22px;
    line-height: 34px;
    background:none;
    padding: 0 6px 0 6px;
    margin: 10px 0 10px 0;
}
.popup-header h3{
    float:left;
    width:100%;
    max-width:calc(100% - 40px);
    font-size:20px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    line-height: 54px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.popup-body {
    float:left;
    padding-top: 10px;
    padding-bottom: 20px;
    height: 300px;
    max-width: 100%;
    overflow-y: auto;
}
.popup-footer{
    float:left;
    border-top:#dfe1e5 1px solid;
    text-align:right;
    padding-top:10px;
    max-width: 100%;
}

.popup-footer button{
  float:right;
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
.notification_SSAS_test{
    z-index:1001;
}
.notification_SSAS_test .popup-header{
    background-color:#ffbe00;
}
.notification_SSAS_test .btn{
    color:#595959;
}
.notification_SSAS_alert{
    z-index:1002;
}
.notification_SSAS_alert .popup-header{
    color:#fff;
    background-color:#fd0043;
}
.record_SSAS_alert .popup-header, .record_SSAS_test .popup-header{
    border-bottom:#dfe1e5 1px solid;
}
.record_SSAS_alert textarea, .record_SSAS_test textarea{
    margin-top:20px;
    height: calc(100% - 30px);
}
.record_SSAS_alert .btn, .record_SSAS_test .btn{
    color:inherit;
}
.record_SSAS_alert .btn-success,
.record_SSAS_test .btn-success,
.record_SSAS_test .btn-danger,
.record_SSAS_test .btn-secondary{
    color:#fff;
}
.custom-table_cont .export_cont{
    clear:both;
    padding-top:20px;
}

.date_picker_container .errorText{
    float:left;
    color:#FF0000;
}
.clearBoth{
    clear:both;
}