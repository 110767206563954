/* styles.scss */
/*
    VESSELS DETAILS
*/
@import '~@fortawesome/fontawesome-svg-core/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
    font-family: 'Poppins', sans-serif !important;
}
#details_page{
    padding:5px;
    width: 100%;
    max-width: 100%;
}
.details_page p, h{
    cursor:default;
}
.details_page .container-fluid{
    padding:0 0 0 0;
}
.details_page .row{
    float:left;
    width:100%;
    clear:both;
    padding-bottom: 10px;
    line-height: 30px;
}
.testing_cont {
    float:left;
    width:300px;
    height:150px;
}
.modal-header{
    width:100%;
}
.modal-content{
    width:100%;
}
.content_header h5{
    float:left;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:#212529;
}
.content_header .icon_container{
    float:right;
    color:#333333;
    font-size:0.6em;
    position:relative;
}
.content_header .icon_container button,
.content_header .icon_container .dropdown-container{
    float:left;
}
.content_header .icon_container button{
    min-width:34px;
}
.content_header .icon_container .dropdown-container button .options_arrow:after{
    /* \e006 or \25be  or   */
    content:"\25bc";
    font-size: 12px;
    display: inline-block;
    padding: 0 0 0 5px;
}
.content_header .icon_container button, .content_container button{
    line-height:20px;
    padding:6px;
    border-color: #cccccc;
}
.content_header .icon_container button{
    margin:0 5px 0 5px;
}
.content_header .badge{
    float:right;
    line-height:21px;
}
.mobile_details .content_container button{
    margin-left:15px;
}
.details_page .content_header.container-fluid{
    float:left;
    font-size:24px;
    padding-top:10px;
    padding-bottom:20px;
}
.content_header .icon_container button:hover, .content_container button:hover{
    border-color: #adadad;
    color: #333;
    background-color: #e6e5e5;
}
.tab_container{
    float: left;
    clear: both;
    padding-left: 0;
    border-bottom: #dedede 1px solid;
}
.tab_container .tab{
    float: left;
    padding: 6px 10px 6px 10px;
    width: auto;
    border: #dedede 1px solid;
    border-bottom: none;
    border-top-right-radius:5px;
    border-top-left-radius:5px;
    background: #fff;
    margin-bottom: -1px;
    font-size: 0.8 rem;
    font-weight:500;
}
.details_page .content_container.container-fluid{
    float:left;
    font-size:19px;
    padding:20px 10px 0 10px;
}
.content_container .label{
    color:#212529;
    font-weight:500;
    font-size:0.8rem;
    font-family: Poppins, sans-serif;
}
.content_container .text{
    color:#212529;
    font-weight:400;
    font-size:0.8rem;
    line-height:20px;
    font-family: Poppins, sans-serif;
}
.mobile_details #update_alias{
    height: 30px;
    line-height: 30px;
}
.mobile_details .modal-content button{
    height: 34px;
    line-height: 12px;
}

/* VESSELS DETAILS */
#vessel_details{}

#vessel_details #details_SSAS_tab{}
#vessel_details #details_SSAS_tab .bg-light{
    color:#000000;
    font-size: 14px;
    line-height: 24px;
    width: calc(100% - 100px);
    max-width: 260px;
    margin-left:20px;
}
#details_SSAS_tab .initial_content .row{
    padding-bottom:20px;
}
#details_SSAS_tab .SSAS_activate_button{
    float:right;
    cursor:pointer;
    margin-left:20px;
}
#details_SSAS_tab .copy{
    float:left;
    width: 30px;
    line-height: 28px;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    border-color:#a4a9ae;
}
#details_SSAS_tab .row .text_cont p{
    float:left;
}
#details_SSAS_tab .initial_content p{
    color:#727e87;
}
#details_SSAS_tab .accordion{
    margin:0;
    padding:0;
    clear:both;
}
#details_SSAS_tab .card,
#details_SSAS_tab .accordion-item,
#details_SSAS_tab .accordion-header{
    border-radius:0;
    border-top:0;
    border-left:0;
    border-right:0;
}

#details_SSAS_tab #vessel_email_for_ssas,
#details_SSAS_tab .email_update{
    float:right;
    max-width:300px;
}
#details_SSAS_tab .email_update{
    margin-left:10px;
}
#details_SSAS_tab .custom-table_cont{
    overflow-x:auto;
}
.details_page #details_SSAS_tab .custom-table td.log_details{
  display: -webkit-box;            /* Use flexbox model for layout */
  -webkit-line-clamp: 2;           /* Limit the number of lines to 2 */
  -webkit-box-orient: vertical;    /* Set box orientation to vertical */
  overflow: hidden;                /* Hide overflowed content */
  text-overflow: ellipsis;         /* Apply ellipsis */
  line-height: 1.5;                /* Adjust line height as necessary */
  max-height: 3em;
  width:100%;
  max-width:200px;
}
#details_SSAS_tab .cascade_cont .userContactSelect,
#details_SSAS_tab .contacts_cont .userContactSelect{
    float:right;
    max-width:200px;
    margin-top:-50px;
    margin-bottom:10px;
}
@media(max-width:700px){
    #details_SSAS_tab .copy{
        clear:both;
    }
    #details_SSAS_tab .initial_content .row{
        padding-bottom:10px;
    }
    #details_SSAS_tab .initial_content .row button{
        margin-bottom:10px;
    }
}